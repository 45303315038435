import {
  COLORS,
  LBTAccordion,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { StaticArticles } from '../../components/StaticArticles';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { SolaryFits } from '../../components/SolaryFits';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxFour,
  AnswerBoxOne,
  AnswerBoxThree,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationThirdBoxes';
import { ARTICLES, Image } from './Fifth';
import {
  navMenuState,
  useBreakpoint,
  useTrackAnalytics,
} from '@laborability/commons';

const ACCORDION_ITEMS = [
  {
    question: 'Cessione del quinto, cos’è',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Qual è il limite per la cessione del quinto?',
    answer: <AnswerBoxTwo />,
  },
  {
    question: 'Si applica anche alla tredicesima mensilità?',
    answer: <AnswerBoxThree />,
  },
  {
    question: 'Si può cedere più di un quinto?',
    answer: <AnswerBoxFour />,
  },
];

const Fifth03 = () => {
  const setNavColor = useSetRecoilState(navMenuState);

  useEffect(() => {
    setNavColor(COLORS.getInstance().WHITE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Cessione del quinto - Articolo 3' },
    { key: 'page_location', value: '/app/cessione-quinto/articolo-3' },
  ]);

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <LBTSpacer spacing={2} />
        <LBTLabel
          variant="delaDisplay"
          component="h1"
          color={COLORS.getInstance().BERRY_POPSICLE_MEDIUM}
        >
          Qual è il limite massimo per la cessione del quinto?
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          La cessione del quinto incontra dei precisi limiti imposti dalla legge
          che non possono essere superati
        </LBTLabel>
        <LBTSpacer spacing={4} />
        {ARTICLES[2].image && <Image src={ARTICLES[2].image} />}

        <LBTLabel
          variant="bodyText"
          textAlign="left"
          paddingBottom={'16px'}
          paddingTop={'48px'}
        >
          La cessione dello stipendio{' '}
          <BoldSpan>non può mai superare il quinto della busta paga</BoldSpan>.
          È un limite inderogabile: la ratio della norma è consentire al
          lavoratore di finanziarsi e di pagare un prestito senza però
          compromettere la propria situazione finanziaria al di sotto di una
          determinata soglia.
        </LBTLabel>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            key={index}
            uniqueKey={`${index}`}
            details={item.answer}
            summary={item.question}
          />
        ))}
        <LBTSpacer spacing={4} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h3">
          Vai ai prossimi articoli
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <StaticArticles toExclude={[2]} />
      </Section>
    </>
  );
};

export default Fifth03;
