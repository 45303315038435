import { snackBarState, useKeycloak, useStartup } from '@laborability/commons';
import md5 from 'md5';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Outlet } from 'react-router-dom';
import { initializeTagManager } from '../utils/gtm';
import { KeycloakContext } from '@laborability/components';
import { currentCompanyConfigState } from '@laborability/commons';
import Loader from './Loader';
import { useRecoilValue } from 'recoil';

const TEST_DOMAINS = [
  'laborability.com',
  'shritalia.com',
  'growthers.io',
  'growthers.it',
  'growthers.com',
  'adv-player.com',
];

export default function Auth() {
  const { keycloak, init } = useKeycloak();
  const { onStartup, isCompletedStartup } = useStartup();

  const currentCompany = useRecoilValue(currentCompanyConfigState);
  const { code } = useRecoilValue(snackBarState);

  useEffect(() => {
    if (code && code === 401) keycloak?.logout();
  }, [code]);

  useEffect(() => {
    initializeTagManager();
    init();
  }, []);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      onStartup();
    }
  }, [keycloak?.tokenParsed]);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          id_user: md5(keycloak?.tokenParsed?.preferred_username),
          company: `company-${currentCompany.id ?? 'id'}`,
          timestamp: new Date().toISOString(),
          is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
        },
      });
      console.log('@@@tagManager', {
        event: 'login',
        company: `company-${currentCompany.id ?? 'id'}`,
        id_user: md5(keycloak?.tokenParsed?.preferred_username),
        timestamp: new Date().toISOString(),
        is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
      });
    }
  }, [currentCompany.id]);

  return (
    <KeycloakContext.Provider value={{ keycloak }}>
      {isCompletedStartup ? <Outlet /> : <Loader />}
    </KeycloakContext.Provider>
  );
}
